.refunded-histories-child-table {
    .product-title {
        white-space: break-spaces;
        word-break: break-word;
    }

    .quantity {
        font-size: 14px;
        font-weight: bold;
    }

    p {
        font-size: 12px;
        line-height: 1.5;
        padding: 0 0 5px;
        margin: 0;
    }
    
}