.btn-tbone-style-1{
    color: #189DAB;
    border-color: #189DAB;
}
.btn-tbone-style-1:hover{
    color: white;
    background-color: #189DAB !important;
    border-color: #189DAB !important;
}

.btn-tbone-style-2{
    color: #F26F65;
    border-color: #F26F65;
}

.btn-tbone-style-2:hover{
    color: white;
    background-color: #F26F65 !important;
    border-color: #F26F65 !important;
}