.picking-ticket-box {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(121, 121, 121, 0.125);
    border-radius: 0.25rem;
    margin-bottom: 15px;
    transition: all 0.3s ease;
    letter-spacing: 0.5px;
    box-shadow: 0 0 20px rgba(8, 21, 66, 0.05) !important;

    .box-body {
        padding: 1rem;
    }

    .picking-ticket-dropdown {
        border: 1px solid #ced4da !important;

        .dropdown-item {
            padding: 0.25rem 0.8rem;
        }
    }
}

.picking-ticket-row {
    margin-left: -7.5px;
    margin-right: -7.5px;

    .picking-ticket-col {
        padding-left: 7.5px;
        padding-right: 7.5px;
    }
}