.finances-summary-page {
    .card {
        position: relative;
    }

    .card-loading {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(255, 255, 255, 0.8); /* Optional: adds a semi-transparent overlay */
        z-index: 2;
        border-radius: 15px;
    }

    .table-summary {
        .commission-percentage {
            // margin: -0.75rem;
            // padding: 0.75rem;
            display: block;
            text-align: right;
            color: var(--rs-text-link);
        }

        .commission-amount {
            display: block;
            text-align: right;
        }

        .table-hover tbody tr:hover {
            background-color: aqua;

            .commission-percentage {
                color: darkblue;
            }
        }
    }

    .table {
        font-size: 12px;
    }

    .table-wrapper {
        max-height: 400px; /* Set the height for the scrollable area */
        overflow-y: auto;  /* Enable vertical scroll */
        position: relative;

        .table thead th,
        .table tfoot td {
            position: sticky;
            z-index: 1;
            background-color: #fff;
        }

        .table thead th {
            top: -2px; /* Stick to the top of the container */
        }
        
        .table tfoot td {
            bottom: 0; /* Stick to the bottom of the container */
        }
        
    }

    .summary-card-box {
        padding: 1rem;

        .card-box-title {
            font-size: 0.9rem;
            font-weight: 400;
            padding: 0;
        }

        .card-box-value {
            font-size: 1.2rem;
            font-weight: 500;
            margin-top: 0.5rem;
            margin-bottom: 0;
            padding: 0;
        }

        .card-box-sub-value {
            font-size: 0.9rem;
            margin-top: 0.2rem;
            margin-bottom: 0;
            padding: 0;
            font-weight: normal;
        }
    }
}
