.button-color {
  color: #1b1e21 !important;
}

.seller {
  position: relative;
  z-index: 3;
}

.rates {
  position: relative;
  z-index: 1; 
}
